const objRowTable = {
  idleads: {
    type: "int",
    key: "idleads",
    etat: 3
    
  },
  created_at: {
    type: "date",
    key: "created_at",
    etat: 3
  },
  idusers: {
    type: "int",
    key: "idusers",
    etat: 3
  },
  s1: {
    type: "int",
    key: "s1",
    etat: 3
  },
  s2: {
    type: "int",
    key: "s2",
    etat: 3
  },
  prenom: {
    type: "string",
    key: "prenom",
    etat: 3
  },
  nom: {
    type: "string",
    key: "nom",
    etat: 3
  },
  crm_id: {
    type: "int",
    key: "crm_id",
    etat: 3
  }
};

export default objRowTable;
