import React, { useEffect, useState } from "react";
import AddLead from "./AddLead";
import UpdateLead from "./UpdateLead";
import { Button, Offcanvas, Table, Card } from "react-bootstrap";
import ReactSelect from "react-select";
// import './pageleads.css'; // Assurez-vous d'importer le fichier CSS
import sortTable from "../../../utils/Table/sortTable";
import { useSelector, useDispatch } from "react-redux";
import TrTable from "./TrTable";
import { setAllLeads, getAllLeads } from "../../Redux/slices/actions.slice";

const actionOptions = [
  { value: "", label: "" },
  { value: "progress", label: "Progress" },
  { value: "create", label: "Create" },
  { value: "finish", label: "Finish" },
];

const objRowTable = {
  idleads: {
    type: "int",
    key: "idleads",
    etat: 3,
  },
  created_at: {
    type: "date",
    key: "created_at",
    etat: 3,
  },
  idusers: {
    type: "int",
    key: "idusers",
    etat: 3,
  },
  s1: {
    type: "int",
    key: "s1",
    etat: 3,
  },
  s2: {
    type: "int",
    key: "s2",
    etat: 3,
  },
  prenom: {
    type: "string",
    key: "prenom",
    etat: 3,
  },
  nom: {
    type: "string",
    key: "nom",
    etat: 3,
  },
  crm_id: {
    type: "int",
    key: "crm_id",
    etat: 3,
  },
};

const PageLeads = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [stateP, setstateP] = useState(objRowTable);

  const [dataArray, setDataArray] = useState([
    {
      idleads: 1,
      created_at: "2023-10-01T08:30:00Z",
      idusers: 1000,
      s1: 1,
      s2: 0,
      nom: "Dupont",
      prenom: "Marie",
      crm_id: 345678,
    },
    {
      idleads: 2,
      created_at: "2023-10-02T09:15:00Z",
      idusers: 1001,
      s1: 0,
      s2: 1,
      nom: "Martin",
      prenom: "Jean",
      crm_id: 456789,
    },
    {
      idleads: 3,
      created_at: "2023-10-03T10:45:00Z",
      idusers: 1002,
      s1: 1,
      s2: 1,
      nom: "Lefèvre",
      prenom: "Sophie",
      crm_id: 567890,
    },
    {
      idleads: 4,
      created_at: "2023-10-04T11:00:00Z",
      idusers: 1003,
      s1: 0,
      s2: 0,
      nom: "Moreau",
      prenom: "Luc",
      crm_id: 678901,
    },
    {
      idleads: 5,
      created_at: "2023-10-05T12:30:00Z",
      idusers: 1004,
      s1: 1,
      s2: 0,
      nom: "Girard",
      prenom: "Claire",
      crm_id: 789012,
    },
    {
      idleads: 6,
      created_at: "2023-10-06T13:45:00Z",
      idusers: 1005,
      s1: 0,
      s2: 1,
      nom: "Bertrand",
      prenom: "Paul",
      crm_id: 890123,
    },
    {
      idleads: 7,
      created_at: "2023-10-07T14:20:00Z",
      idusers: 1006,
      s1: 1,
      s2: 1,
      nom: "Petit",
      prenom: "Anne",
      crm_id: 901234,
    },
    {
      idleads: 8,
      created_at: "2023-10-08T15:10:00Z",
      idusers: 1007,
      s1: 0,
      s2: 0,
      nom: "Rousseau",
      prenom: "Pierre",
      crm_id: 112233,
    },
    {
      idleads: 9,
      created_at: "2023-10-09T16:25:00Z",
      idusers: 1008,
      s1: 1,
      s2: 0,
      nom: "Blanc",
      prenom: "Élodie",
      crm_id: 223344,
    },
    {
      idleads: 10,
      created_at: "2023-10-10T17:50:00Z",
      idusers: 1009,
      s1: 0,
      s2: 1,
      nom: "Garnier",
      prenom: "Thomas",
      crm_id: 334455,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const AllLeads = useSelector(getAllLeads);

  useEffect(() => {
    if (AllLeads.length === 0) {
      dispatch(setAllLeads(dataArray));
    }
  }, [AllLeads]);

  // dispatch(setAllLeads(dataArray));

  const handleCloseAdd = () => setShowAdd(false);
  const handleShowAdd = () => setShowAdd(true);

  const handleCloseUpdate = () => {
    setShowUpdate(false);
    setSelectedLead(null);
  };
  const handleShowUpdate = (lead) => {
    setSelectedLead(lead);
    setShowUpdate(true);
  };

  const handleActionChange = (option) => {
    if (option === null) {
      setSelectedAction(null);
      // Reset or update other states as needed when cleared
    } else {
      setSelectedAction(option);
      // Handle the selected action
    }
    // console.log(`Selected action: ${option.label}`);
    // Ajoutez votre logique d'action ici
  };

  const handleSelectRow = (id) => {
    if (selectedLeads.includes(id)) {
      setSelectedLeads(selectedLeads.filter((item) => item !== id));
    } else {
      setSelectedLeads([...selectedLeads, id]);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "#10d078",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#10D078",
      },
      backgroundColor: "white",
      minHeight: "30px",
      height: "30px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "5px",
      padding: "0px 5px",
      position: "relative",
      zIndex: 100,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#10D078",
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#10D078",
      textAlign: "center",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#10D078",
      backgroundColor: state.isSelected ? "#e6ffe6" : "white",
      "&:hover": {
        backgroundColor: "#e6ffe6",
        color: "#10D078",
      },
      cursor: "pointer",
      textAlign: "center",
      borderRadius: "5px",
      lineHeight: "29px",
      minHeight: "40px",
      height: "40px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0 8px",
      color: "#10D078",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#10D078",
      ":hover": {
        color: "#007b55",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#10D078",
      ":hover": {
        backgroundColor: "#e6ffe6",
        color: "#007b55",
      },
    }),
  };
  // c'est notre devoir de dire la rumeur sur (la meuf )aharon a Jordan
  // apres lui il dit ou il dit pas
  const stateRow = (row, type) => {
    if (stateP[row].etat === 3) {
      setstateP({ ...objRowTable, [row]: { etat: 1 } });

      sortTable(row, type, AllLeads, 1, dispatch, setAllLeads);
    } else if (stateP[row].etat === 1) {
      setstateP({ ...objRowTable, [row]: { etat: 2 } });

      sortTable(row, type, AllLeads, 2, dispatch, setAllLeads);
    } else if (stateP[row].etat === 2) {
      setstateP({ ...objRowTable, [row]: { etat: 3 } });

      sortTable(row, type, AllLeads, 3, dispatch, setAllLeads);
    }
  };

  const handleSearch = (e) => {
    // console.log(e.target.value)
    setSearchTerm(e.target.value);
  };

  useEffect(() => {

    if (!searchTerm.trim()) {
      dispatch(setAllLeads(dataArray));
      return;
    }
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const newFilteredLeads = AllLeads.filter((lead) => {
      const prenomMatch = lead.prenom
        .toLowerCase()
        .includes(lowerCaseSearchTerm);
      const nomMatch = lead.nom.toLowerCase().includes(lowerCaseSearchTerm);
      const crmMatch = lead.crm_id.toString().includes(lowerCaseSearchTerm);

      return prenomMatch || nomMatch || crmMatch;
    });
    dispatch(setAllLeads(newFilteredLeads));
  }, [searchTerm]);

  return (
    <Card className="col-12 p-0">
      <Card.Header className="p-3">
        <div className="pageleads-search-input-container my-auto">
          <div className="pageleads-input-group">
            <input
              type="text"
              className="pageleads-form-control pageleads-rounded-start bg-grey-lead"
              // placeholder="Search..."
              aria-label="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <span className="pageleads-input-group-text pageleads-rounded-end pageleads-search-icon bg-grey-lead">
              <i className="bi bi-search"></i>
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <ReactSelect
            value={selectedAction}
            onChange={handleActionChange}
            options={actionOptions}
            placeholder="Action"
            styles={customStyles}
            className="me-1"
            menuPosition="fixed"
            menuPortalTarget={document.body}
            isClearable
          />

          <button
            className="border btn  text-black h-50 me-2 py-1"
            onClick={handleShowAdd}
            style={{
              borderRadius: "5px",
              fontSize: "14px",
              backgroundColor: "#10d078",
            }}
          >
            Create Lead
          </button>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {/* {selectedLeads.length > 1 && (
          <div className="selected-count mb-2 text-black">
            {selectedLeads.length} lignes sélectionnées
          </div>
        )} */}
        <Table striped bordered hover className=" p-0">
          <thead>
            {stateP && <TrTable stateP={stateP} stateRow={stateRow} />}
          </thead>
          <tbody>
            {AllLeads?.map((lead) => (
              <tr
                key={lead.idleads}
                style={{ cursor: "pointer" }}
                onClick={() => handleShowUpdate(lead)}
              >
                <td onClick={(e) => e.stopPropagation()}>
                  <div
                    className={`custom-checkbox-container ${
                      selectedLeads.includes(lead.idleads) ? "checked" : ""
                    }`}
                    onClick={() => handleSelectRow(lead.idleads)}
                  >
                    <div
                      className={`custom-checkbox ${
                        selectedLeads.includes(lead.idleads) ? "checked" : ""
                      }`}
                    ></div>
                  </div>
                </td>
                <td>{lead.idleads}</td>
                <td>
                  {new Date(lead.created_at).toLocaleString().slice(0, 10)}
                </td>
                <td>{lead.nom}</td>
                <td>{lead.prenom}</td>
                <td>{lead.crm_id}</td>
                <td>{lead.s1}</td>
                <td>{lead.s2}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>

      <Offcanvas show={showAdd} onHide={handleCloseAdd} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Ajouter Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddLead />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showUpdate}
        onHide={handleCloseUpdate}
        data={selectedLead}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Mettre à jour Lead</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedLead && <UpdateLead lead={selectedLead} />}
        </Offcanvas.Body>
      </Offcanvas>
    </Card>
  );
};

export default PageLeads;
