import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import ReactSelect from "react-select";

const UpdateLead = ({ lead }) => {
  const [crmId, setCrmId] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [s1, setS1] = useState(null);
  const [s2, setS2] = useState(null);
  const [leadToSend, setLeadToSend] = useState(null);
console.log("lead", lead)
  useEffect(() => {
    if (lead) {
      setCrmId(lead.crm_id || "");
      setNom(lead.nom || "");
      setPrenom(lead.prenom || "");
      setS1(lead.s1 !== undefined ? { value: lead.s1, label: lead.s1.toString() } : null);
      setS2(lead.s2 !== undefined ? { value: lead.s2, label: lead.s2.toString() } : null);
    }
  }, [lead]);

  if (!lead) {
    return <div>Aucun lead sélectionné.</div>;
  }

  const sOptions = [
    { value: 1, label: "1" },
    { value: 0, label: "0" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedLead = {
      crm_id: crmId,
      nom,
      prenom,
      s1: s1 ? s1.value : lead.s1,
      s2: s2 ? s2.value : lead.s2,
      idleads: lead.idleads,
      idusers: lead.idusers,
      created_at: lead.created_at,
    };
    console.log("Lead à envoyer:", updatedLead);
    setLeadToSend(updatedLead);
    // Réinitialiser les champs du formulaire si nécessaire
    // setCrmId("");
    // setNom("");
    // setPrenom("");
    // setS1(null);
    // setS2(null);
    // Ajouter la logique d'envoi du lead ici
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#26e87f" : provided.borderColor,
      backgroundColor: "white",
      cursor: "pointer",
      height:"45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      padding: "0px 5px",
      boxShadow: state.isFocused ? "0 0 0 1px #26e87f" : provided.boxShadow,
      "&:hover": {
        borderColor: "#26e87f",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
    //   color: "#26e87f",
    }),
    placeholder: (provided) => ({
      ...provided,
    //   color: "#26e87f",
      textAlign: "center",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#26e87f",
      backgroundColor: state.isSelected ? "#e6ffe6" : "white",
      "&:hover": {
        backgroundColor: "#e6ffe6",
        color: "#26e87f",
      },
      cursor: "pointer",
      textAlign: "center",
      borderRadius: "5px",
      lineHeight: "29px",
    }),
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} className="grid-container text-black">
      <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Crm_id</div>
          <input
            as={Row}
            // controlId="formCrmId"
            type="text"
            className="form-control"
            placeholder=""
            required
            value={crmId}
            onChange={(e) => setCrmId(e.target.value)}
            readOnly
          />
        </div>

        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Prenom</div>
          <input
            as={Row}
            // controlId="formCrmId"
            type="text"
            className="form-control"
            placeholder=""
            required
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
          />
        </div>

        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">Nom</div>
          <input
            as={Row}
            // controlId="formCrmId"
            type="text"
            className="form-control"
            placeholder=""
            required
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
        </div>

        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">S1</div>
          <ReactSelect
            value={s1}
            onChange={setS1}
            options={sOptions}
            styles={customStyles}
            // isClearable
          />
        </div>

        <div className="grid-item p-0 me-1">
          <div className="mx-2 mb-1">S2</div>
          <ReactSelect
            value={s2}
            onChange={setS2}
            options={sOptions}
            styles={customStyles}
            // isClearable
          />
        </div>

        {/* Ajoutez d'autres champs si nécessaire */}

        <div className="grid-item p-0 me-1 full-width mt-2">
          <Button
            type="submit"
            className="border pointer btn btn-green text-black me-2 col-12"
          >
            Valider
          </Button>
        </div>
      </Form>

      {leadToSend && (
        <div className="mt-4">
          <h6>Objet à envoyer:</h6>
          <pre>{JSON.stringify(leadToSend, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default UpdateLead;