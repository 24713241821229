import React from "react";
import objRowTable from "./objRowTable";
import arrowSort from "./arrowSort";

const TrTable = ({ stateP, stateRow }) => {
    console.log("stateRow",stateRow)
    console.log("stateP",stateP)
  return (
    <tr className="titre-tableau text-center">
      <th
        className="fixed-column fs-14 fw-bold text-black"
        // onClick={() => {
        //   stateRow(objRowTable.created_at.key, objRowTable.created_at.type);
        // }}
      >
        {/* <div className="d-flex align-items-center justify-content-center">
          <div></div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.created_at.key].etat)}
          </div>
        </div> */}
      </th>
      <th
        className="fixed-column fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.idleads.key, objRowTable.idleads.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div>ID</div>
          <div className="ms-1">
            {arrowSort(stateP.idleads.etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-2 fs-14 fw-bold text-black m-auto"
        onClick={() => {
          stateRow(objRowTable.created_at.key, objRowTable.created_at.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">Date Création</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.created_at.key].etat)} */}
            {arrowSort(stateP.created_at.etat)}
          </div>
        </div>
      </th>

      <th
        className="fixed-column-3 fs-14 fw-bold text-black text-center"
        onClick={() => {
          stateRow(objRowTable.nom.key, objRowTable.nom.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">Nom</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.nom.key].etat)} */}
            {arrowSort(stateP.nom.etat)}

          </div>
        </div>
      </th>

      <th
        className="fixed-column-4 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.prenom.key, objRowTable.prenom.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">Prenom</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.prenom.key].etat)} */}
            {arrowSort(stateP.prenom.etat)}

          </div>
        </div>
      </th>

      <th
        className="fixed-column-5 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.crm_id.key, objRowTable.crm_id.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">Crm ID</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.crm_id.key].etat)} */}
            {arrowSort(stateP.crm_id.etat)}

            
          </div>
        </div>
      </th>

      <th
        className="fixed-column-6 fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.s1.key,
            objRowTable.s1.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">S1</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.s1.key].etat)} */}
            {arrowSort(stateP.s1.etat)}

          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.s2.key, objRowTable.s2.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">S2</div>
          <div className="ms-1">
            {/* {arrowSort(sortRow[objRowTable.s2.key].etat)} */}
            {arrowSort(stateP.s2.etat)}

          </div>
        </div>
      </th>

      {/* <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.paymentdudate.key,
            objRowTable.paymentdudate.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">ECHEANCE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.paymentdudate.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(
            objRowTable.pseudo_agt_supp.key,
            objRowTable.pseudo_agt_supp.type
          );
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">SUPPORT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.pseudo_agt_supp.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.psp.key, objRowTable.psp.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">PSP</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.psp.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.neobank.key, objRowTable.neobank.type);
        }}
      >
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <div className="">PLATEFORME</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.neobank.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">Arrivée </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.customerbank.key, objRowTable.customerbank.type);
        }}
      >
        {" "}
        <div className="d-flex align-items-center justify-content-center">
          <div className="">BANK CLIENT</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.customerbank.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">En preuve </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.date_vir.key, objRowTable.date_vir.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">DATE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.date_vir.key].etat)}
          </div>
        </div>
      </th>

      <th className="vertical-text fs-14 fw-bold text-black">
        ENCAISSEE <br />{" "}
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.date_encaisse.key, objRowTable.date_encaisse.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">DATE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.date_encaisse.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.encash_usd.key, objRowTable.encash_usd.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">$</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.encash_usd.key].etat)}
          </div>
        </div>
      </th>

      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.next_call.key, objRowTable.next_call.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">APPEL</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.next_call.key].etat)}
          </div>
        </div>
      </th>
      <th
        className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable.hours.key, objRowTable.hours.type);
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">HEURE</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable.hours.key].etat)}
          </div>
        </div>
      </th>
      <th className="vertical-text fs-14 fw-bold text-black">Reporter</th>
      <th className="vertical-text fs-14 fw-bold text-black">Annuler</th>
      <th className=" fs-14 fw-bold text-black"
        onClick={() => {
          stateRow(objRowTable?.comments_timestamp?.key, objRowTable?.comments_timestamp?.type);
        }}
        >
        <div className="d-flex align-items-center justify-content-center">
          <div className="">last Comments</div>
          <div className="ms-1">
            {arrowSort(sortRow[objRowTable?.comments_timestamp.key]?.etat)}
          </div>
        </div>
      </th>
      <th className=" fs-14 fw-bold text-black text-left">COMMENTAIRE</th> */}
    </tr>
  );
};

export default TrTable;
